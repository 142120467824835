import logger from '@evidentid/universal-framework/logger';

const SUPPORTED_FONT_SIGNATURES = {
    WOFF2: 0x774F4632,
    WOFF: 0x774F4646,
    TTF: 0x00010000,
    OTF: 0x4F54544F,
};

export function buildFileFromBlob(blob: Blob, fileName: string, type?: string): File {
    try {
        return new File([ blob ], fileName, { type: type || blob.type });
    } catch (e) {
        // Hack for Microsoft Edge: doesn't support File constructor
        const pseudoFile = new Blob([ blob ], { type: type || blob.type });
        // @ts-ignore: name is not a real Blob property
        pseudoFile.name = fileName;
        return pseudoFile as File;
    }
}

export function downloadFile(file: Blob, filename: string): void;
export function downloadFile(file: File, filename?: string): void;
export function downloadFile(file: File | Blob, filename?: string): void {
    const name = filename || ('name' in file ? file.name : undefined);
    if (name == null) {
        throw new Error('You need to pass the File or attach the file name.');
    }

    const downloadableFile = buildFileFromBlob(file, name, 'application/octet-stream');
    const url = (window.URL || window.webkitURL).createObjectURL(downloadableFile);
    const a = document.createElement('a');

    a.style.display = 'none';
    a.href = url;
    a.download = name;

    document.body.appendChild(a);
    a.click();

    URL.revokeObjectURL(url);
    a.remove();
}

export function downloadFileUsingBinString(content: string, filename: string): void {
    return downloadFile(new Blob([ content ]), filename);
}

export function downloadFileUsingBase64String(base64Str: string, filename: string): void {
    const data = new Uint8Array([ ...atob(base64Str) ].map((char) => char.charCodeAt(0)));
    return downloadFile(new Blob([ data ]), filename);
}

function arrayBufferToBase64(arrayBuffer: ArrayBuffer): string {
    let binary = '';
    const bytes = new Uint8Array(arrayBuffer);

    for (let i = 0; i < bytes.byteLength; i++) {
        binary += String.fromCharCode(bytes[i]);
    }

    return btoa(binary);
}

// When the FileReader is not supported
function fallbackReadFile(blob: Blob | File, operation: 'readAsDataURL' | 'readAsArrayBuffer'): Promise<string> {
    return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.open('GET', URL.createObjectURL(blob), true);
        xhr.responseType = 'arraybuffer';

        xhr.onload = function xhrOnload() {
            if (xhr.status === 200) {
                const arrayBuffer = xhr.response;
                if (operation === 'readAsDataURL') {
                    const base64 = arrayBufferToBase64(arrayBuffer);
                    resolve(`data:${blob.type};base64,${base64}`);
                } else {
                    resolve(arrayBuffer);
                }
            } else {
                reject(new Error(`Failed to load Blob: Status ${xhr.status}`));
            }
        };

        xhr.onerror = function xhrOnerror() {
            reject(new Error('Failed to load Blob'));
        };

        xhr.send();
    });
}

function _readFile(blob: Blob, operation: 'readAsDataURL'): Promise<string>;
function _readFile(blob: Blob, operation: 'readAsArrayBuffer'): Promise<ArrayBuffer>;
function _readFile(blob: Blob | File, operation: 'readAsDataURL' | 'readAsArrayBuffer'): Promise<string | ArrayBuffer> {
    return new Promise((resolve, reject) => {
        if (!window.FileReader) {
            console.error('window.FileReader is not supported');
            fallbackReadFile(blob, operation).then(resolve, reject);
        } else {
            const fileReader = new window.FileReader();

            fileReader.addEventListener('load', () => {
                if (!fileReader.result) {
                    return reject(fileReader.error || new Error('Something went wrong while reading File - empty File received'));
                }
                resolve(fileReader.result as any);
            }, { capture: false, once: true });

            fileReader.addEventListener('error', (e) => {
                logger.error(`${operation}: ${fileReader.error?.message} [type: ${e.type}, phase: ${e.eventPhase}, full: ${e.loaded === e.total ? 'yes' : 'no'}]`);
                reject(e);
            }, { capture: false, once: true });

            fileReader[operation](blob);
        }
    });
}

export const readFile = {
    asDataURL: (blob: Blob) => _readFile(blob, 'readAsDataURL'),
    asArrayBuffer: (blob: Blob) => _readFile(blob, 'readAsArrayBuffer'),
};

function getMimeTypeFromSignature(arrayBuffer: ArrayBuffer): string | null {
    const signature = new DataView(arrayBuffer).getUint32(0, false);
    for (const [ key, value ] of Object.entries(SUPPORTED_FONT_SIGNATURES)) {
        if (signature === value) {
            return `font/${key.toLowerCase()}`;
        }
    }
    return null;
}

export async function verifyAndConvertToDataURI(blob: Blob): Promise<string> {
    // Read the blob as a Data URL
    let dataUri = await readFile.asDataURL(blob);

    // Read the first 4 bytes of the blob to determine the file signature
    const arrayBuffer = await readFile.asArrayBuffer(blob.slice(0, 4));

    // Determine the MIME type based on the signature
    const mimeType = getMimeTypeFromSignature(arrayBuffer);

    if (!mimeType) {
        throw new Error(
            'Unsupported font file. The font file signature does not match any supported font file signature.'
        );
    }

    // Update to correct mime type if needed
    if (!dataUri.includes(mimeType)) {
        dataUri = dataUri.replace(/data:.*?;/, `data:${mimeType};`);
    }

    // Return the Data URL with the correct MIME type
    return dataUri;
}

export async function cloneFile(file: File): Promise<File> {
    const arrayBuffer = await readFile.asArrayBuffer(file);
    try {
        return new File([ arrayBuffer ], file.name, { type: file.type });
    } catch (e) {
        // Hack for Microsoft Edge: doesn't support File constructor
        const pseudoFile = new Blob([ arrayBuffer ], { type: file.type });
        // @ts-ignore: name is not a real Blob property
        pseudoFile.name = file.name;
        return pseudoFile as File;
    }
}
