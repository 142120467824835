import { render, staticRenderFns } from "./FormElement.vue?vue&type=template&id=5f0c092b"
import script from "./FormElement.vue?vue&type=script&lang=ts"
export * from "./FormElement.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@3.5.13_babel-core@7.0.0-bridge.0_@babel+core@7.26.0__css_yn7p3up3wisoxbfwvppfso2v34/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports